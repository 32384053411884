<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Informasjonskapsler
        </h1>
        <div class="article-body clearfix mb-3">
          <h2 class="mt-0">Informasjonskapselerklæring for Europower</h2>
          <p>
            Revisjon 1. juli 2018
          </p>
          <p class="mt-5">
            <strong>1. Informasjonskapselerklæringen</strong><br>
            Denne informasjonskapselerklæringen forklarer hvordan Europower og Europowers samarbeidspartnere benytter informasjonskapsler (cookies) ved bruk av Europowers nyhetsbrev, («Nyhetsbrevene»), nettsteder som websider tilknyttet Europower.com og Energi-nett.no («Nettstedene»). Nyhetsbrevene og nettstedene er heretter samlet omtalt som «Tjenestene».
          </p>
          <p>
            Tjenestene eies og drives av Europower AS, org. nr. 916 055 137 med forretningsadresse Christian Krohgs gate 16, 0186 OSLO, postadresse Postboks 1182, 0107 OSLO («Europower»). Europower er heleid av DN Media Group AS («NHST»). NHST og dets datterselskaper omtales heretter samlet som «Konsernet». Informasjon om Konsernet finnes på <a href="https://www.nhst.no">www.nhst.no</a>.
          </p>
          <p class="mt-5">
            <strong>2. Om informasjonskapsler</strong><br>
            En informasjonskapsel er en tekstfil som inneholder en liten mengde data som lastes ned til og lagres på enheten du bruker for å få tilgang til Tjenestene. Informasjonskapsler kalles ofte for «cookies». Slike informasjonskapslene brukes til ulike formål, blant annet for å la Tjenestene gjenkjenne enheten når du bruker Tjenestene igjen, sørge for at du får tilsendt riktig innhold, til å lagre informasjon om dine preferanser eller tjenester for deg og til å levere, tilpasse og måle effekten av annonser. Sporingsbilder, eller andre lignende filer, kan også gjøre det samme. Europower bruker begrepet "informasjonskapsler" i denne erklæringen for å referere til alle filene som samler inn informasjon på denne måten. Siden Konsernet benytter samme basis informasjonssystemer og har felles tekniske plattformer og IT-avdeling, kan data – herunder data fra informasjonskapsler – behandles av Konsernet for administrative formål.
          </p>
          <p class="mt-5">
            <strong>3. Samtykke til bruk av informasjonskapsler</strong><br>
            Når du går inn på og tar i bruk Nettstedene eller Nyhetsbrevene, eller laster ned og tar i bruk Appene, eller på annen måte bruker Tjenestene, samtykker du til at Europower og Europowers samarbeidspartnere plasserer og leser informasjonskapsler på din enhet som beskrevet i denne erklæringen.
          </p>
          <p>
            Europower trenger ikke samtykke for å bruke informasjonskapsler utelukkende for det formål å overføre kommunikasjon i et elektronisk kommunikasjonsnett som Tjenestene som er nødvendig for å levere Tjenestene etter din uttrykkelige forespørsel. Samtykke kan også avgis ved at du benytter en teknisk innstilling i nettleser eller tilsvarende i tilfeller der dette er teknisk mulig og effektivt. Forhåndsinnstilling i nettleseren din om at du aksepterer informasjonskapsler/cookies, anses som samtykke. Det er tilstrekkelig at du samtykker én gang for det samme formålet. Du har til enhver tid mulighet til å trekke tilbake ditt samtykke.
          </p>
          <p class="mt-5">
            <strong>4. Vår bruk av informasjonskapsler</strong><br>
            Europower bruker informasjonskapsler om deg til tre overordnede formål:
            <ol>
              <li>
                For å kunne levere og tilpasse Tjenestene til deg,
              </li>
              <li>
                For å opprettholde og videreutvikle Tjenestene og
              </li>
              <li>
                For å kunne levere, tilpasse og måle effekten av annonser og annen markedsføring som vises til deg som bruker av Tjenestene.
              </li>
            </ol>
          </p>
          <p>
            Nærmere informasjon om disse formålene er beskrevet i vår <a href="https://privacy.nhst.no/personvernerklaering">Personvernerklæring</a>.
          </p>
          <p>
            For å nå disse formålene bruker vi ulike former for informasjonskapsler.
          </p>
          <p>
            Sesjonsavhengige informasjonskapsler:
          </p>
          <p>
            Sesjonsavhengige informasjonskapsler er informasjonskapsler som slettes umiddelbart når du lukker nettleseren din. Vi bruker slike informasjonskapsler blant annet for å registrere at du er inne på våre digitale løsninger, og hvordan du navigerer.
          </p>
          <p>
            Faste informasjonskapsler:
          </p>
          <p>
            Faste informasjonskapslene brukes til å hente informasjon om autentisering, språkinnstillinger og andre preferanser og valg. Faste informasjonskapsler slettes ikke når du lukker nettleseren, men på en senere angitt utløpsdato eller når du selv fjerner dem manuelt. Ved å bruke faste informasjonskapsler kan vi sørge for at bruk av våre digitale løsninger går raskere og er bedre tilpasset dine preferanser neste gang du logger deg på. Vi bruker for eksempel faste informasjonskapsler til å huske din innlogging.
          </p>
          <p>
            Informasjonskapsler plassert av våre samarbeidspartnere:
          </p>
          <p>
            For å levere, tilpasse og måle effekten av annonser i Tjenestene har vi gitt våre annonsesamarbeidspartnere adgang til å plassere og lese informasjonskapsler på din enhet når du bruker Tjenestene. Slike tredjepartsinformasjonskapsler kan våre være faste og sesjonsavhengige gjør dem i stand til å samle inn opplysninger om deg.
          </p>
          <p>
            Hvis du klikker på lenker levert av tredjeparter, vil vilkårene for slike tredjepartssider gjelde, inkludert vilkårene for informasjonskapsler som tredjeparter gjør tilgjengelig for deg avhengig av dine handlinger.
          </p>
          <p>
            For nærmere informasjon om informasjonskapsler plassert av våre samarbeidspartnere, se vår <a href="https://privacy.nhst.no/personvernerklaering">Personvernerklæring</a>. Der finner du også informasjon om hvordan du kan reservere deg mot innsamling og analyse av data og annonsemålretting fra våre samarbeidspartnere.
          </p>
          <p class="mt-5">
            <strong>5. Kontroll av bruk av informasjonskapsler</strong><br>
            Du kan når som helst stenge av for informasjonskapsler ved å velge dette i nettleseren din. Disse innstillingene er vanligvis funnet i "alternativer" eller "innstillinger"-menyen i nettleseren.
          </p>
          <p>
            Vi gjør oppmerksom på at det finnes verktøy som kan kartlegge og/eller gi deg oversikt over hvilke informasjonskapsler som benyttes på sidene du besøker. For å lese mer om informasjonskapsler og om hvordan du sletter dem, se <a href="http://www.mediebedriftene.no/informasjonskapsler">www.mediebedriftene.no/informasjonskapsler</a> eller <a href="http://nettvett.no/slik-administrer-du-informasjonskapsler/">nettvett.no/slik-administrer-du-informasjonskapsler/</a>. Du kan også velge bort innsamling og bruk av informasjon for annonsemålretting fra andre aktører <a href="http://optout.aboutads.info/?c=2&lang=EN">her</a> og <a href="http://www.youronlinechoices.eu/">her</a>.
          </p>
          <p>
            Vær oppmerksom på at noen av våre funksjoner eller tjenester ikke vil fungere dersom du velger å slå av informasjonskapsler. Din nettleser vil for eksempel ikke huske din pålogging automatisk dersom informasjonskapsler er slått av.
          </p>
          <p class="mt-5">
            <strong>6. Type opplysninger som behandles</strong><br>
            En informasjonskapsel inneholder typisk anonymiserte data om bruk av Tjenestene slik at Europower og Europowers samarbeidspartnere ikke vet at dette er opplysninger om deg. En informasjonskapsel kan likevel i noen tilfelle inneholde personopplysninger som navn, påloggingsinformasjon nødvendig for å bruke Tjenestene, data om din enhet, dine valg ved bruk av Tjenestene og lignende, samt informasjon som sammen med andre datasett kan knyttes til din person.
          </p>
          <p>
            Ytterligere informasjon om våre bruk av informasjonskapsler, herunder hvilke informasjonskapsler som brukes, hvilke opplysninger som behandles, formål og hvem som behandler opplysningene for de ulike Tjenestene, er tilgjengelige i vår <a href="https://privacy.nhst.no/personvernerklaering">Personvernerklæring</a>.
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'cookies-page',
  extends: Page
};
</script>
